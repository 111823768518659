<template>
  <div class="page">
    <div class="dkzc-box">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: 'index-syllabus' }"
          >考纲教材</el-breadcrumb-item
        >
        <el-breadcrumb-item>考纲</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <section v-html="content"></section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },

  created() {
    this.xin.isLoadding(true);
    this.getContent(this.$route.query.type);
    this.xin.isLoadding(false);
  },

  methods: {
    async getContent(type) {
      let res = await this.$newsApi.getHomeGet_home({ type: type });
      if (res.code == 200) {
        this.content = res.data.content;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  margin: 0;
  padding: 0;
  top: 0;

  > .dkzc-box {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
  }
}
</style>
